////
/// ============================================================================
/// LAZYLOAD
/// ============================================================================
///
/// @group vendors
////

.lazyload {
    opacity: 0;
    position: relative;

    &.loading {
        opacity: 1;
        transition: opacity 300ms;
    }

    &.loaded {
        opacity: 1;
    }
}

.has-media-loading {
    min-height: 32px;
    position: relative;

    &::after {
        @include center(center, 32px, 32px);
        @extend .c-spinner;
        content: '';
        opacity: 1;
        transition: opacity 300ms;
    }
}

.has-media-loaded {
    position: relative;

    &::after {
        opacity: 0;
    }
}

img[data-aspectratio]:not([width]) {
    width: 100%;
}
