////
/// ============================================================================
/// COMPONENT / AuthorBox
/// ============================================================================
///
/// @group components
////

.c-author-box {
    &__wrap {
        @include fluid-type(padding, 1rem, 2rem);
        display: flex;
        flex-wrap: wrap;
    }
    &__avatar {
        margin-right: 1em;
    }
    &__details {
        flex: 1;
        p {
            color: color(text, light);
            margin-bottom: 0;
        }
    }
}