////
/// ============================================================================
/// GENERIC / Media
/// ============================================================================
///
/// @group generics
////

//
// 1. Setting `vertical-align` removes the whitespace that appears under `img`
//    elements when they are dropped into a page as-is. Safer alternative to
//    using `display: block;`.
//
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle; /* [1] */
}

//
// Add the correct display in iOS 4-7.
//
audio:not([controls]) {
    display: none;
    height: 0;
}

iframe {
    border: 0;
    width: 100%;
}

figure {
    margin: 0;
}


//
// Fluid media for responsive purposes.
//
img, video, svg {
    max-width: 100%;
    height: auto;
}

//
// Offset `alt` text from surrounding copy.
//
img {
    font-style: italic;
}

//
// SVG elements
//
// @see https://www.24a11y.com/2018/accessible-svg-icons-with-inline-sprites/
//
svg {
    flex-shrink: 0;
    height: 1em;
    position: relative;
    transition: fill 0.3s;
    width: 1em;
    fill: currentColor;

    a & {
        pointer-events: none;
    }
}
