////
/// ============================================================================
/// UTILITY / Media
/// ============================================================================
///
/// @group utilities
////

.u-video-embed {
    height: 0;
    padding-bottom: percentage(9 / 16);
    position: relative;

    iframe {
        @include absolute(top 0 left 0);
        height: 100%;
        width: 100%;
    }

    .wp-block-embed & {
        height: inherit;
        padding-bottom: inherit;
    }
}