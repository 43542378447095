////
/// ============================================================================
/// GUTENBERG - BUTTONS
/// ============================================================================
///
/// @group vendors/gutenberg
////

.wp-block-buttons {
    font-size: 0;

    .wp-block-button {
        color: inherit;
        font-size: 1rem;
        margin: 0;

        &__link {
            @extend %c-btn;
        }
    }
}

.wp-block-button {
    &.is-style-default .wp-block-button__link {
    }

    &.is-style-ghost .wp-block-button__link {
        @extend .c-btn.-ghost;
    }

    &.is-style-outline .wp-block-button__link {
        @extend .c-btn.-outline;
    }

    // Negative (Pulsanti su findo scuro)
    &.is-style-negative .wp-block-button__link {
        @extend .c-btn.-negative;
    }

    &.is-style-negative-ghost .wp-block-button__link {
        @extend .c-btn.-ghost;
        @extend .c-btn.-ghost.-negative;
    }

    &.is-style-negative-outline .wp-block-button__link {
        @extend .c-btn.-outline;
        @extend .c-btn.-outline.-negative;
    }
}
