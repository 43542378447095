////
/// ============================================================================
/// GUTENBERG - MEDIA & TEXT
/// ============================================================================
///
/// @group vendors/gutenberg
////

.wp-block-media-text {
    grid-column-gap: calc(#{gutter()} - #{$scrollbar-width / 2});
    grid-row-gap: calc(#{gutter()} - #{$scrollbar-width / 2});
    grid-template-columns: repeat(2, 1fr);

    &.is-stacked-on-mobile {
        @include media("<m") {
            grid-row-gap: 0;
            grid-template-columns: 100% !important;
            .wp-block-media-text__content {
                grid-column: 1;
                grid-row: 2;
                padding-bottom: 2rem;
                padding-top: 2rem;
            }
            .wp-block-media-text__media {
                grid-column: 1;
                grid-row: 1;
                margin-left: -$container-side;
                margin-right: -$container-side;
            }
            &.has-media-on-the-right {
                .wp-block-media-text__content {
                    grid-column: 1;
                    grid-row: 2;
                }

                .wp-block-media-text__media {
                    grid-column: 1;
                    grid-row: 1;
                }
            }
        }
    }

    .wp-block-media-text__content {
        padding-left: 0;
        padding-right: 0;

        @include media(">=l") {
            padding-left: calc(#{span(1 of 5)} + #{gutter(5)});
        }

        .alignfull & {
            @include media(">=l") {
                padding-left: calc(#{span(1 of 6)} + #{gutter(6)});
                padding-right: calc(#{span(1 of 6)} + #{gutter(6)});
            }
        }
    }

    &.has-media-on-the-right {
        grid-template-columns: repeat(2, 1fr);

        .wp-block-media-text__content {
            @include media(">=l") {
                padding-left: 0;
                padding-right: calc(#{span(1 of 5)} + #{gutter(5)});
            }

            .alignfull & {
                @include media(">=l") {
                    padding-left: calc(#{span(1 of 6)} + #{gutter(6)});
                    padding-right: calc(#{span(1 of 6)} + #{gutter(6)});
                }
            }
        }
    }

    .-expand & {
        .wp-block-media-text__media {
            @include media(">=l") {
                margin-left: -$container-side;
            }
            @include media(">=#{$container}") {
                margin-left: calc(((100vw - #{$container} - #{$scrollbar-width}) / -2));
            }
        }

        &.has-media-on-the-right {
            .wp-block-media-text__media {
                @include media(">=l") {
                    margin-left: 0;
                    margin-right: -$container-side;
                }
                @include media(">=#{$container}") {
                    margin-right: calc(((100vw - #{$container} - #{$scrollbar-width}) / -2));
                }
            }
        }
    }
}
