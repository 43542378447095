/* --------------------------------

File#: _3_expandable-img-gallery
Title: Expandable Image Gallery
Descr: Image gallery that expands in a modal media gallery on click
Usage: codyhouse.co/license

-------------------------------- */

// demo gallery
.exp-gallery {
    img {
        display: block;
        transition: opacity .3s;
        width: 100%;

        &:hover {
            cursor: pointer;
            opacity: 0.85;
        }
    }
}

.exp-gallery-item-hidden { // hide original item when its 'clone' is animating
    opacity: 0;
}

.exp-lightbox {
    background-color: #fff;

    .slideshow__content {
        position: relative;
    }
}

.exp-lightbox__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.exp-lightbox__header {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 0.5em 1.25em 0;
    position: relative;
    z-index: 2;
}

.exp-lightbox__title {
    font-size: 2em;
    margin-right: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.exp-lightbox__body {
    flex-grow: 1;
    padding: 2.25em; // slideshow padding
    position: relative;
    z-index: 1;

    @include media(">=m") {
        padding: 0.5em calc(0.5em + 1.6em + 2.25em); // 0.5 + slideshow-btn-width + slideshow-btn-offset
    }
}

// slideshow style
.js .exp-lightbox {
    .slideshow {
        align-items: stretch;
        display: flex;
    }

    .slideshow__content {
        flex-grow: 1;
    }

    .slideshow__item {
        background-color: transparent;
        height: 100%;
    }
}

.exp-lightbox--no-transition .slideshow__content * { // used in JS to select the first visible slide
    animation: none !important;
    transition: none !important;
}

// single slide content style
.exp-lightbox__media { // figure element wrapping images
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
}

.exp-lightbox__media-outer {
    flex-grow: 1;
    position: relative;
    width: 100%;

    .slideshow__content--is-hidden & { // hide image during expanding animation
        opacity: 0;
    }
}

.exp-lightbox__media-inner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;

    img {
        display: inline-block;
        height: auto;
        max-height: 100%;
        width: auto;
    }
}

.js .exp-lightbox.modal--is-visible { // make sure imgs are draggable and zoomable
    .slideshow[data-swipe="on"][data-zoom="on"] .exp-lightbox__media-inner img {
        pointer-events: auto;
        user-select: none;
    }
}

.exp-lightbox__caption { // img caption
    flex-shrink: 0;
    font-size: 0.8em;
    margin-top: 0.5em;
    max-width: 600px;
    text-align: center;
}

.exp-lightbox__media-outer { // flex fallback
    min-height: 200px;

    @supports (display: flex) {
        min-height: 0;
    }
}

.exp-lightbox__clone-img-wrapper { // image clone created and animated in JS
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transform-origin: top left;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    visibility: hidden;
    will-change: transform;
    z-index: 15;

    svg, rect, image {
        height: 100%;
        width: 100%;
    }
}

.exp-lightbox__clone-img-wrapper--is-visible {
    opacity: 1;
    visibility: visible;
}

// img zoom-in enabled
.exp-lightbox__body[data-zoom="on"] .exp-lightbox__media-inner img {
    cursor: zoom-in;
}

.exp-lightbox__zoom { // zoom-in image wrapper - created in JS
    background-color: #fff;
    cursor: zoom-out;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    transition: opacity 0s 0.3s, visibility 0s 0.3s;
    visibility: hidden;
    width: 100%;
    z-index: 3;

    img {
        display: block;
        max-width: none;
        transform-origin: top left;
        transition: transform 0.3s;
        width: 100%;
    }
}

.exp-lightbox__zoom--no-transition img {
    transition: none;
}

.exp-lightbox__zoom--is-visible {
    opacity: 1;
    transition: none;
    visibility: visible;
}