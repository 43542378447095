////
/// ============================================================================
/// ARCHIVE
/// ============================================================================
///
/// @group templates
////

.o-archive {
    &__header {
        align-items: center;
        display: flex;

        & > div {
            position: relative;
        }
    }

    &__title {
        @include media(">=m") {
            width: span(10);
        }
        @include media(">=l") {
            width: span(6);
        }

        p {
            opacity: 0.9;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &.-center {
            margin-left: auto;
            margin-right: auto;
        }
    }
}


/**
 * Entries
 */
.o-entries {
    @include fluid-type(margin-top margin-bottom, -1rem, -1.5rem); // .c-entry
    @include clearfix;
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;

    .o-archive & {
        @include fluid-type(margin-top margin-bottom, -1rem, -2rem); // .c-entry
    }

    &.-center {
        justify-content: center;
    }

    @each $breakpoint in map-keys($breakpoints) {
        @include media(">=#{$breakpoint}") {
            &.-col2\@#{$breakpoint} > * {
                margin-right: gutter();
                width: span(6);

                &:nth-of-type(2n),
                &:last-of-type {
                    margin-right: 0;
                }
            }

            &.-col3\@#{$breakpoint} > * {
                margin-right: gutter();
                width: span(4);

                &:nth-of-type(2n) {
                    margin-right: gutter();
                }

                &:nth-of-type(3n),
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}


/**
 * Products
 */
.o-products {
    @include fluid-type(margin-top margin-bottom, -2rem, -6rem);
}
