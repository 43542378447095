////
/// ============================================================================
/// COMPONENT / Logo
/// ============================================================================
///
/// @group components
////

.c-logo {
    align-items: center;
    color: color(secondary, base);
    display: flex;
    margin: 0;
    overflow: hidden;

    svg {
        height: 100%;
        width: 100%;
    }

    &.-header {
        svg {
            max-height: 50px;
            max-width: 116px;

            @include media(">=m") {
                max-height: 86px;
                max-width: 200px;
            }
        }
    }

    &.-footer {
        color: #fff;
        max-height: 50px;
        max-width: 116px;
        margin-bottom: 0.5rem;
    }
}
