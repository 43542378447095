////
/// ============================================================================
/// COMPONENT / Features
/// ============================================================================
///
/// @group components
////

.c-features {
    list-style: none;
    padding: 0;
    @include media(">=s") {
        @include gridAuto(360px, gutter());
        @supports (grid-area: auto) {
            grid-row-gap: 3rem;
        }
    }

    &__item {
        cursor: default;
        display: block;

        & + li {
            margin-top: 2rem;

            @include media(">=s") {
                margin-top: 0;
            }
        }

        &:hover {
            h4 {
                color: color(primary, base);
            }
        }

        .c-btn.-ghost {
            padding: 0;
        }
    }

    &__image {
        flex-shrink: 0;
        margin-bottom: 1rem;
        max-width: 3.5em;
        width: 100%;
    }

    &__text {
        width: 100%;
        @include media(">=s") {
            flex: 1;
        }

        h4 {
            margin-bottom: 1rem;
        }
    }

    // List
    &.-list {
        .c-features__item {
            align-items: center;
            display: flex;
            flex-wrap: nowrap;

            h4 {
                margin-bottom: 0;
            }
        }

        .c-features__image {
            flex-grow: 1;
            margin-bottom: 0;
            margin-right: 2em;
        }
    }

    // Chess
    &.-chess {
    }

    // Important
    &.-important {
        .c-features__item {
            text-align: center;

            h4 {
                @extend .h1;
                font-weight: $font-weight-book;
            }
        }

        .c-features__text {
            padding-left: 1em;
            padding-right: 1em;
        }

        .c-features__image {
            max-width: none;
        }
    }
}
