////
/// ============================================================================
/// UTILITY / Duotone
/// ============================================================================
///
/// @group utilities
////

.u-duotone {
	background-color: color(secondary, base);

	&::before {
		@include absolute(top 0 left 0, 1);
		background-color: #000;
		content: '';
		height: 100%;
		opacity: 0.2;
		width: 100%;
	}

	img {
		filter: grayscale(100%) contrast(1) blur(0);
		opacity: 0.7;
		position: relative;
	}
}
