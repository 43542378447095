/* --------------------------------

File#: _2_slideshow
Title: Slideshow
Descr: Show a collection of items one at a time
Usage: codyhouse.co/license

-------------------------------- */

// slide
.slideshow__item {
    align-items: center;
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 280px; // slideshow height
    justify-content: center;

    &:focus {
        outline: none;
    }

    @include media(">=m") {
        height: 400px;
    }

    @include media(">=l") {
        height: 480px;
    }
}

// use this variation if you want to set the ratio of the slides to 16/9 (e.g., if you have an image slider)
.slideshow--ratio-16\:9 .slideshow__item {
    height: 0;
    padding-bottom: 56.25%; // 16:9 aspect ratio
}

// slideshow basic style
.js {
    .slideshow {
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    .slideshow__content {
        overflow: hidden;
    }

    .slideshow__item {
        left: 0;
        position: absolute;
        top: 0;
        visibility: hidden;
        width: 100%;
        z-index: 1;
    }

    .slideshow__item--selected {
        position: relative;
        visibility: visible;
        z-index: 3;
    }
}

// fade-in animation
.js {
    .slideshow--transition-fade .slideshow__item {
        opacity: 0;
        transition: opacity 0s 0.25s, visibility 0s 0.25s;
    }

    .slideshow--transition-fade .slideshow__item--selected {
        opacity: 1;
        transition: opacity 0.25s, visibility 0.25s;
    }
}

// slide-in animation
.js {
    .slideshow--transition-slide .slideshow__item {
        animation-duration: 0.35s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        > * { // hide content of not-visible items so that they are not focusable
            visibility: hidden;
        }
    }

    .slideshow--transition-slide .slideshow__item--selected > * { // show content of selected item
        visibility: visible;
    }

    .slideshow--transition-slide .slideshow__item--slide-in-left { // visible item - enter from left to right
        animation-name: slide-in-left;
    }

    .slideshow--transition-slide .slideshow__item--slide-in-right { // visible item - enter from right to left
        animation-name: slide-in-right;
    }

    .slideshow--transition-slide .slideshow__item--slide-out-left { // leaving item - leave from left to right
        animation-name: slide-out-left;
    }

    .slideshow--transition-slide .slideshow__item--slide-out-right { // leaving item - leave from right to left
        animation-name: slide-out-right;
    }

    .slideshow--transition-slide .slideshow__item--slide-out-left,
    .slideshow--transition-slide .slideshow__item--slide-out-right {
        z-index: 2;

        &.slideshow__item--selected {
            z-index: 3; // fix bug on browsers not supporting CSS animations
        }

        > * {
            visibility: visible;
        }
    }
}

@keyframes slide-in-left {
    0% {
        visibility: visible;
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-in-right {
    0% {
        visibility: visible;
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-out-left {
    0% {
        visibility: visible;
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes slide-out-right {
    0% {
        visibility: visible;
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}


// touch swipe enabled
.js .slideshow[data-swipe="on"] .slideshow__content {
    user-select: none;

    img {
        pointer-events: none;
    }
}

// slideshow arrow controls
.slideshow__control {
    display: none;
}

.js {
    .slideshow[data-swipe="on"] .slideshow__control {
        display: none; // hide arrows on mobile if swipe is enabled
    }

    .slideshow__control {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 4;

        &:first-of-type {
            left: 0.5em;
        }

        &:last-of-type {
            right: 0.5em;
        }
    }

    @include media(">=m") {
        .slideshow[data-swipe="on"] .slideshow__control {
            display: block; // show arrow controls
        }
    }
}

.slideshow__btn {
    background-color: rgba(#000, 0.75); // IE fallback
    border-radius: $border-radius;
    cursor: pointer;
    display: block;
    height: 3.2em;
    padding: 0;
    transition: background .2s, transform 0.2s;
    width: 1.6em;

    &:hover {
        background-color: rgba(color(text, base), 0.85);

        svg {
            color: #fff;
        }
    }

    .slideshow:not(.slideshow--is-animating) &:active { // active effect
        transform: scale(0.95);
    }

    svg {
        color: #fff; // IE fallback
        display: block;
        height: 1.6em;
        margin: 0 auto;
        transition: color .2s;
        width: 1.6em;
    }

    @supports (grid-area: auto) {
        background-color: transparent; // button color

        svg {
            color: color(text, base); // icon color
        }
    }
}

// slideshow navigation
.slideshow__navigation { // created in JS
    align-items: center;
    background: transparent;
    bottom: 0;
    display: flex;
    height: 32px;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 4;
}

.slideshow__nav-item {
    display: inline-block; // flex fallback
    margin: 0 0.5em;

    button { // dot
        background-color: currentColor;
        border-radius: 50%;
        color: color(text, base); // dot color
        cursor: pointer;
        display: block;
        font-size: 8px; // dot size
        height: 1em;
        opacity: 0.4;
        position: relative;
        transition: background .3s;
        width: 1em;

        &::before { // focus circle
            border: 1px solid color(text, base);
            border-radius: inherit;
            content: '';
            font-size: 14px;
            height: 1em;
            left: calc(50% - 0.5em);
            opacity: 0;
            position: absolute;
            top: calc(50% - 0.5em);
            transform: scale(0);
            transition: 0.3s;
            width: 1em;
        }

        &:focus {
            outline: none;

            &::before {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

.slideshow__nav-item--selected button {
    opacity: 1;
}

@include media(">=m") {
    .slideshow__navigation {
        height: 40px;
    }

    .slideshow__nav-item {
        button {
            font-size: 10px; // dot size

            &::before { // focus circle
                font-size: 16px;
            }
        }
    }
}