////
/// ============================================================================
/// UTILITY / Positions
/// ============================================================================
///
/// @group utilities
////

/**
 * Cover
 */
.u-cover {
    @include absolute(top 0 left 0, 1);
    height: 100%;
    width: 100%;
}


/**
 * Object-fit
 */
.u-object-cover {
    font-family: 'object-fit: cover;'; // Polyfill IE11 libraries/ofi.js
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.u-object-contain {
    font-family: 'object-fit: contain;'; // Polyfill IE11 libraries/ofi.js
    object-fit: contain;
    width: 100%;
    height: 100%;
}