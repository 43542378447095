////
/// ============================================================================
/// OBJECT / Section
/// ============================================================================
///
/// @group objects
////

.o-section {
    position: relative;

    &.-reduced {
        margin-left: auto;
        margin-right: auto;
        @include media(">=l") {
            width: span(10);
        }
    }

    &__header {
        &.-reduced {
            @include media(">=l") {
                width: span(8);

                .o-content & {
                    width: span(8 of 10);
                }

                .o-content .alignfull & {
                    width: span(8);
                }
            }
        }

        &.-center {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }

        & > span {
            color: color(primary, base);
            display: block;
            font-weight: $font-weight-semibold;
            margin-bottom: 0.5rem;
            text-transform: uppercase;

            .u-bg-night & {
                color: #fff;
                opacity: 0.95;
            }
        }

        h2, h3 {
            margin: 0;
        }

        p {
            opacity: 0.95;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__content {
        &.-reduced {
            margin-left: auto;
            margin-right: auto;
            @include media(">=l") {
                width: span(8);

                .o-content & {
                    width: span(8 of 10);
                }

                .o-content .alignfull & {
                    width: span(8);
                }
            }
        }
    }
}
