////
/// ============================================================================
/// COMPONENT / Comments
/// ============================================================================
///
/// @group components
////

/**
 * Comment
 */
.c-comment {
    &__meta {
        align-items: center;
        display: flex;
        flex-wrap: wrap;

        .c-avatar {
            flex: 0 0 auto;
        }
    }

    &__author {
        flex: 1;
        padding: 0 1em;

        time {
            display: block;
        }
    }

    &__body {
        margin-bottom: 2rem;
    }

    &__content {
        font-size: 0.9em;
    }

    &__reply {
        width: 100%;
        @include media(">=s") {
            width: auto;
        }

        a {
            align-items: center;
            color: #000;
            display: flex;
            font-size: 14px;
            opacity: 0.6;
            text-decoration: none;

            &::before {
                background-color: transparent;
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='#000000' d='M10,11h6v7h2v-8c0-0.553-0.447-1-1-1h-7V6l-5,4l5,4V11z'/%3E%3C/svg%3E");
                background-origin: content-box;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 90%;
                content: '';
                display: block;
                height: 24px;
                width: 24px;
            }
        }
    }

    .c-comment {
        margin-left: 2em;
    }
}


/**
 * Respond
 */
.comment-respond {
}

.comment-form {
    &-author {
        @include media(">=s") {
            margin-right: gutter();
        }
        @include media(">=m") {
            margin-right: gutter(of 8);
        }
        @include media(">=l") {
            margin-right: gutter(of 6);
        }
    }

    &-author,
    &-email {
        @include media(">=s") {
            float: left;
            width: span(6);
        }
        @include media(">=m") {
            width: span(4 of 8);
        }
        @include media(">=l") {
            width: span(3 of 6);
        }
    }

    &-comment,
    &-author,
    &-email {
        margin-top: 0;
    }

    #{text-inputs()} {
        @extend %c-input;
    }

    textarea {
        @extend %c-textarea;
    }

    .form-submit {
        @include clearfix;
        text-align: center;
    }

    &-cookies-consent {
        clear: both;
        display: flex;
        input {
            margin-right: 0.5em;
        }
    }

    .submit {
        @extend %c-btn;
        @extend .c-btn.-outline;
    }
}