/* --------------------------------

File#: _2_flexi-header
Title: Flexi Header
Descr: Customizable header template
Usage: codyhouse.co/license

-------------------------------- */

.f-header {
    height: $f-header-mobile-height;
    position: relative;
    z-index: 99;
    @include media(">=l") {
        height: $f-header-desktop-height;
    }

    &__mobile-content { // logo + menu button on small devices
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: space-between;
        max-width: 80rem;
        position: relative;
        z-index: 2;

        @include media(">=l") {
            display: none; // hide logo + menu (mobile content)
        }
    }

    &__logo {
        display: block;
        flex-shrink: 0;
        max-width: 220px;
        text-decoration: none;
        width: auto;

        svg, img {
            display: block;
        }
    }

    &__nav {
        @include absolute(top 0 left 0, 1);
        background-color: #fff;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.085), 0 8px 24px rgba(0, 0, 0, 0.1);
        font-size: 17px; // edit font size of all nav elements
        max-height: 100vh;
        opacity: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior: contain;
        padding: 0 0 1em;
        transform: translateY(-1em);
        visibility: hidden;
        width: 100%;


        @include media(">=l") {
            // reset
            background-color: transparent;
            box-shadow: none;
            height: $f-header-desktop-height;
            max-height: none;
            opacity: 1;
            overflow: visible;
            overscroll-behavior: auto;
            padding: 0;
            position: static;
            transform: translateY(0);

            // new style
            transition: none;
            visibility: visible;
        }

        &::before { // top header bg + border
            background-color: inherit;
            border-bottom: 1px solid color(gray, 300);
            content: '';
            display: block;
            height: $f-header-mobile-height;
            position: sticky;
            top: 0;
            @include media(">=l") {
                display: none;
            }
        }

        &--is-visible {
            opacity: 1;
            transform: translateY(0);
            transition: visibility 0s, opacity 0.3s, transform 0.3s;
            visibility: visible;
        }

        &-grid {
            padding-left: $container-side;
            padding-right: $container-side;
            @include media(">=l") {
                align-items: center;
                display: flex;
                height: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }

        &-logo-wrapper {
            display: none; // hide logo nav on small devices

            @include media(">=l") {
                display: block; // fallback
                display: flex;
                margin-right: gutter();
            }
        }
    }

    &__form-control {
        flex-basis: 0;
        flex-grow: 1;
        @include media(">=l") {
            align-items: center;
            display: flex;
            justify-content: center;
            padding-left: 2em;
            padding-right: 2em;
        }

        .c-search-form {
            padding-left: 1em;
            padding-right: 1em;
            width: 100%;
            @include media(">=l") {
                max-width: 600px;
            }

            &__button {
                background: transparent;
                color: color(text, base);
                padding: 0.5em;

                span {
                    display: none;
                }
            }
        }
    }

    &__list {
        flex-basis: auto;
        flex-grow: 0;
        list-style: none;
        margin: 0;
        padding: 0;
        @include media(">=l") {
            align-items: center;
            display: flex;
            justify-content: flex-end;
        }

        &.-header {
        }

        &.-getintouch {
            @include media(">=l") {
                margin-left: auto;
            }
        }
    }

    &__item {
        border-bottom: 1px solid color(gray, 300);
        flex-shrink: 0;

        &:last-child {
            border-bottom: 0;
        }

        @include media(">=l") {
            border-bottom: none;
            display: inline-block; // fallback
            padding-right: 1.5em; // margin between nav items
            position: relative;

            &:last-child {
                padding-right: 0;
            }
        }

        &.button {
            a {
                @extend %c-btn;
                width: 100%;
                @include media(">=l") {
                    width: auto;
                }
            }
        }
    }

    &__link {
        align-items: center; // align icon with text
        color: color(gray, 600);
        display: block;
        display: flex;
        padding: 0.5em 0;
        text-decoration: none;

        .is-active > &,
        &:hover {
            color: color(primary, base);
            text-decoration: none;
        }

        @include media(">=l") {
            padding: 0.5em;
        }

        .has-children > &::after {
            @extend %chevron;
            display: none;
            right: -10px;
            @include media(">=l") {
                display: block;
            }
        }
    }

    &__btn,
    &__form-control {
        margin: 0.5em 0;
        width: 100%;
        @include media(">=l") {
            margin: 0; // reset
        }
    }

    &__dropdown { // sub navigation
        list-style: none;
        margin: 0;
        padding-left: $container-side; // offset sub nav
        @include media(">=l") {
            @include absolute(top 100% left calc(50% - 120px), 5);
            background-color: #fff;
            border-radius: $border-radius;
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.085), 0 8px 24px rgba(0, 0, 0, 0.1);
            opacity: 0;
            padding: 0.5em 0;
            transition: visibility 0.2s 0.2s, opacity 0.2s 0s;

            // hide
            visibility: hidden;
            width: 240px;

            .f-header__item:hover & {
                opacity: 1;
                transition: visibility 0.2s 0s, opacity 0.2s 0s;
                visibility: visible;
            }
        }

        li {
            border-bottom: 0;
            @include media(">=l") {
                display: block;
                padding-right: 0;
            }
        }

        a {
            @include media(">=l") {
                color: color(gray, 600);
                padding: 0.5em 1em;

                &:hover {
                    background-color: color(gray, 100);
                }
            }
        }
    }
}

html:not(.js) {
    .f-header {
        &__nav-control {
            display: none;
        }

        &__nav {
            box-shadow: none;
            opacity: 1;
            padding: 0.5em 1em;
            position: static;
            transform: translateY(0);
            visibility: visible;
        }
    }
}
