////
/// ============================================================================
/// OBJECT / Spinner
/// ============================================================================
///
/// @group components
////

.c-spinner {
    animation-animation-delay: 0s;
    animation-direction: normal;
    animation-duration: 0.5s;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-name: spinner;
    animation-play-state: running;
    animation-timing-function: linear;
    border: 4px solid transparent;
    border-left: 4px solid color(primary, base);
    border-radius: 50%;
    border-top: 4px solid color(primary, base);
    cursor: wait;
    display: block;
    min-height: 30px;
    min-width: 30px;
    opacity: 1;
    pointer-events: none;
    transition: all 200ms cubic-bezier(0.72, 0.01, 0.56, 1);
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}