/* --------------------------------

File#: _3_hiding-nav
Title: Auto Hiding Navigation
Descr: A Navigation that auto-hides when the user scrolls down, and is revealed when they scrolls back up
Usage: codyhouse.co/license

-------------------------------- */

.hide-nav {
    position: sticky;
    top: 0;
    transition: transform 0.3s, background-color 0.3s;
    will-change: transform;

    &--fixed {
        background-color: transparent;
        left: 0;
        position: fixed;
        width: 100%;
    }

    &--has-bg {
        background-color: #fff;
    }

    &--off-canvas {
        // main header is off-canvas
        // add off-canvas custom style (if any)

        transform: translateY(0) !important; // Disabilito l'off-canvas
    }
}
