////
/// ============================================================================
/// COMPONENT / Attachements
/// ============================================================================
///
/// @group components
////

/**
 * Download List
 */
.c-download {
    list-style: none;
    padding-left: 0;
    &__item {
        border-top: 1px solid color(gray, 300);
        &:first-child {
            border-top: 0;
        }
    }
}

// Attachement
.c-attach {
    color: color(text, base);
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover {
        color: color(text, base);
        text-decoration: none;
        h4 {
            color: color(primary, base);
        }
    }
    &__icon {
        flex: 0;
        margin-right: 1em;
    }
    &__details {
        flex: 1;
        span,
        small {
            display: block;
        }
    }
}

// File Icon
.c-file {
    background: linear-gradient(45deg, #eee 0%, #fff 100%);
    border: 1px solid color(gray, 400);
    color: black;
    display: block;
    height: 60px;
    position: relative;
    width: 50px;
    &::before {
        @include absolute(bottom 12px left -4px);
        background: color(gray, 400);
        color: #fff;
        content: 'file';
        content: attr(data-type);
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 11px;
        padding: 3px 6px;
        text-transform: uppercase;
    }
    &::after {
        @include absolute(top -2px right -2px);
        border-color: white transparent transparent color(gray, 200);
        border-style: solid;
        border-width: 16px 0 0 15px;
        content: '';
        display: block;
        height: 0;
        width: 0;
    }

    $filetypes: pdf #fb6363,
                doc #1c9eec,
                docx #1c9eec,
                csv #26c184,
                xls #26c184 !default;

    @each $filetype in $filetypes {
        $type: nth($filetype, 1);
        $color: nth($filetype, 2);
        &[data-type="#{$type}"] {
            border-color: $color;
            &::before {
                background: $color;
            }
            &::after {
                border-left-color: $color;
            }
        }
    }
}