////
/// ============================================================================
/// UTILITY / Visibility
/// ============================================================================
///
/// @group utilities
////

.u-hidden {
    @include hide(hidden);
}

.u-accessibility {
    @include hide(visuallyhidden);
}