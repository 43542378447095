////
/// ============================================================================
/// COMPONENT / Badge
/// ============================================================================
///
/// @group components
////

.c-badge {
    @extend .u-type.-xs;
    border-radius: 5em;
    display: inline-block;
    font-weight: $font-weight-bold;
    margin: 0;
    padding: 6px;
    text-align: center;
    text-decoration: none;
    position: relative;

    &:hover {
        color: color(primary, base);
        text-decoration: none;
    }

    &.-seats {
        $alerts: success, info, warning, error;
        border: 1px solid color(gray, 300);

        @each $alert in $alerts {
            $color: color($alert);

            &.-#{$alert} {
                @if $alert == 'error' {
                    background: $color;
                    border-color: $color;
                    color: #fff;
                } @else {
                    border-color: $color;
                    color: $color;
                }
            }
        }
    }
}
