////
/// ============================================================================
/// GENERIC / Generic
/// ============================================================================
///
/// @group generics
////

html {
    box-sizing: border-box;
}

*,
:before,
:after {
    box-sizing: inherit;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
    touch-action: manipulation; // Single taps should be dispatched immediately on clickable elements
}

[hreflang] > abbr[title] {
    text-decoration: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
}

th, td {
    text-align: left;
    vertical-align: top;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
picture,
section,
form,
legend {
    display: block;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}