////
/// ============================================================================
/// COMPONENT / Avatar
/// ============================================================================
///
/// @group components
////

.c-avatar {
    background: #fff;
    border-radius: 30rem;
    box-shadow: 0 0 0 2px #fff;
    color: #fff;
    display: inline-block;
    height: 3em; // em altrimenti non ridimensiona
    position: relative;
    width: 3em; // em altrimenti non ridimensiona

    img {
        @extend .u-object-cover;
        border-radius: inherit;
        display: block;
        overflow: hidden;
    }

    &.-small {
        font-size: 0.8em;
    }
    &.-medium {
        font-size: 1.5em;
    }
    &.-large {
        font-size: 2.2em;
    }
    &.-huge {
        font-size: 3em;
    }
}

.c-avatar-group {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        padding: 0;
        &:not(:nth-child(1)) {
            margin-left: -1em;
        }
    }
}