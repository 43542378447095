////
/// ============================================================================
/// FOOTER
/// ============================================================================
///
/// @group templates
////

.o-footer {
	&__sitemap {
		position: relative;
		@include media(">=l") {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	&__copyright {
		color: color(text, light);
		padding-left: gutter();
		padding-right: gutter();
		text-align: center;
		@include media(">=l") {
			margin-left: auto;
			margin-right: auto;
			max-width: span(10);
			text-align: left;
		}

		a {
			color: color(text, light);

			&:not(:first-of-type) {
				@include media(">=l") {
					padding-left: 1.5em;
				}
			}
		}
	}
}


/**
 * Brands
 */
.c-brands {
	display: flex;
	flex-direction: column;
	margin: 0 auto 1rem auto;
	max-width: 175px;

	@include media(">=l") {
		margin: 0;
	}
}

.c-certicates {
	align-items: center;
	display: flex;
	gap: 1em;
	justify-content: center;
	list-style: none;
	margin: 0;
	padding: 0;

	@include media(">=l") {
		justify-content: flex-end;
	}

	li {
		max-width: 50px;
	}
}

/**
 * Sitemap
 */
.c-sitemaps {
	@include media(">=l") {
		display: flex;
		flex-basis: auto;
		flex-grow: 0;
	}
}

.c-sitemap {
	width: 100%;
	@include media(">=l") {
		margin-left: 2em;
		margin-right: 2em;
		width: auto;
	}

	&:not(:first-of-type) {
		border-top: 1px solid color(gray, 300);
		@include media(">=l") {
			border-top: none;
		}
	}

	&:not(.c-dropdown) {
		border-top: 0;
		margin-top: 2rem;
		text-align: center;
		@include media(">=l") {
			margin-top: 0;
			text-align: left;
		}

		h5 {
			display: none;
			@include media(">=l") {
				display: block;
			}
		}
	}

	label {
		border: none;
		color: #fff;
		padding: 1rem 0;
		@include media(">=l") {
			padding: 0;
		}

		svg {
			right: 0;
			@include media(">=l") {
				display: none;
			}
		}
	}

	&__title {
		color: #fff;
		font-weight: $font-weight-semibold;
		margin-bottom: 0;
		margin-top: 0;
		text-transform: uppercase;
		@include media(">=l") {
			margin-bottom: 1rem;
		}
	}

	&__list {
		border: none;
		padding-bottom: 1rem;
		@include media(">=l") {
			display: block;
			padding-bottom: 0;
		}

		&.-cols {
			@include media(">=s") {
				column-count: 2;
			}
		}

		a {
			color: rgba(#fff, 0.9);
			line-height: 1.6;
			text-decoration: none;

			&:hover {
				color: color(primary, base);
			}
		}
	}
}


/**
 * Follow us
 */
.c-followus {
	display: inline-block;
	position: relative;
	width: 100%;
	@include media(">=l") {
		flex: 0 1 auto;
		width: auto;
	}

	.c-social {
		justify-content: center;
	}
}


/**
 * Copyright
 */
.c-copyright {
	@include media(">=l") {
		display: flex;
		flex-wrap: wrap;
	}

	&__left {
		@include media(">=l") {
			flex: 1;
		}
	}

	&__right {
	}

	a {
		text-decoration: none;
	}
}


.c-copyright-nav {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
	margin: 0 -0.5em;
	padding: 0;

	&__item {
		padding: 0 0.5em;
	}
}
