////
/// ============================================================================
/// COMPONENT / Modal Video
/// ============================================================================
///
/// @group components
////

.modal-video {
    &__content {
        max-height: calc(100vh - (1.25 * 1em));
        max-width: 1024px;
        opacity: 0;
        overflow: auto;
        width: calc(100% - 2 * (1.25 * 1em));
    }

    &__wrap {
        height: 0;
        padding-bottom: 56.25%; // 16:9 aspect ratio
        position: relative;

        iframe, video, img {
            @include absolute(top 0 left 0);
            height: 100%;
            width: 100%;
        }

        video, img {
            object-fit: cover;
        }
    }

    // loader icon - visible while the iframe is being loaded
    &__loader {
        @include transform();
    }

    // iframe content has been loaded
    &--loaded {
        .modal-video__content {
            opacity: 1;
        }

        .modal-video__loader {
            display: none;
        }
    }
}
