////
/// ============================================================================
/// COMPONENT / Lists
/// ============================================================================
///
/// @group components
////

.c-list {
    line-height: normal;
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        display: inline-block;
        font-weight: $font-weight-bold;
        position: relative;

        &:not(:last-of-type) {
            padding-right: 0.5em;
        }

        a {
            color: currentColor;
            text-decoration: none;
        }
    }

    &.-meta {
        align-items: center;
        color: color(text, light);
        display: flex;
        flex-wrap: wrap;
        text-transform: uppercase;

        &:not(:last-of-type) {
            padding-right: 1.5em;
            &:after {
                @include absolute(top 50% right 0.75em);
                background: color(text, light);
                content: "";
                display: block;
                height: 80%;
                transform: skewX(-20deg) translateY(-50%);
                width: 2px;
            }
        }
    }
}
