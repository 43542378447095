////
/// ============================================================================
/// UTILITY / Align
/// ============================================================================
///
/// @group utilities
////

/**
 * Text
 */
.u-text-left,
.alignleft {
    text-align: left;
}

.u-text-right,
.alignright {
    text-align: right;
}

.u-text-center,
.aligncenter {
    text-align: center;
}

.u-center {
    margin-left: auto;
    margin-right: auto;
}


/**
 * Media
 */
audio,
canvas,
iframe,
img,
svg,
video {
    &.aligncenter {
        display: block;
        margin: auto;
    }
}


/**
 * Layout
 */
.alignwide,
.u-widewidth {
    @include media(">=#{$container}") {
        margin-left: calc(((50% - 50vw) + #{$scrollbar-width  / 2}) / 2);
        margin-right: calc(((50% - 50vw) + #{$scrollbar-width  / 2}) / 2);
    }
}

.alignfull,
.u-fullwidth {
    margin-left: calc((50% - 50vw) + #{$scrollbar-width  / 2});
    margin-right: calc((50% - 50vw) + #{$scrollbar-width  / 2});

    @include media(">=#{$container}", "<#{$container + px($container-side * 2)}") {
        padding-left: $container-side;
        padding-right: $container-side;
        width: 100vw;
    }
}


.alignwide,
.alignfull,
.u-widewidth,
.u-fullwidth {
    max-width: 100vw;
    width: auto;

    & > div {
        margin-left: auto;
        margin-right: auto;
        max-width: $container;
        padding-left: $container-side;
        padding-right: $container-side;
        width: 100%;
        @include media(">=#{$container}") {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
