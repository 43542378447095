////
/// ============================================================================
/// SWIPPER CUSTOMIZATION
/// ============================================================================
///
/// @group vendors
////

.swiper-pagination-bullet {
    background: transparent;
    height: 18px;
    opacity: 1;
    position: relative;
    width: 18px;

    &:focus {
        outline: none;
    }

    &::before {
        @include center(center, 50%, 50%);
        background: color(gray, 300);
        border-radius: 100%;
        content: '';
        display: block;
    }

    &-active {
        background: transparent;

        &::before {
            background: transparent;
            box-shadow: inset 0 0 0 2px color(primary, base);
            height: 100%;
            margin: 0;
            position: static;
            width: 100%;
        }
    }
}

.swiper-slide {
    height: auto;
}
