////
/// ============================================================================
/// HOME
///
/// 01. Hero
/// 02. Products
/// 03. Insights
/// ============================================================================
///
/// @group templates
////

/**
 * Hero
 */
.c-hero {
	position: relative;

	&__item {
		align-items: center;
		background: color(secondary, base);
		display: flex;
		flex-wrap: wrap;
		height: 100%;
	}

	&__text {
		text-align: center;
		width: 100%;
		@include media(">=m") {
			margin-right: gutter();
			text-align: left;
			width: span(6);
		}
		@include media(">=l") {
			width: span(5);
		}

		& > * {
			opacity: 0;
			transform: translateY(25px);
			transition: all 0.4s;

			.swiper-slide-active & {
				opacity: 1;
				transform: none;

				@for $i from 0 to 5 {
					&:nth-child(#{$i + 1}) {
						transition-delay: $i * 0.1 + 0.3s;
					}
				}
			}
		}
	}

	&__media {
		overflow: hidden;
		text-align: center;
		width: 100%;
		@include media(">=m") {
			width: span(6);
		}
		@include media(">=l") {
			width: span(7);
		}

		img {
			transition: all 0.3s;

			.swiper-slide-active & {
				opacity: 1;
				transition-delay: 0.3s;
			}
		}
	}

	&__pag {
		background: color(secondary, base);
		position: relative;
		z-index: 10;

		@include media(">=l") {
			position: absolute;
			top: 50%;
			right: 0;
		}
	}
}


/**
 * Progress
 *
 * @see https://codepen.io/xgad/post/svg-radial-progress-meters
 */
.c-progress {
	margin: 0 auto 1rem auto;
	position: relative;
	width: 50px;
	@include media(">=m") {
		margin: 0;
	}

	&__fraction {
		@include center(center, 14px, 50px);
		bottom: auto;
		font-size: 14px;
		letter-spacing: 2px;
		line-height: 14px;
		overflow: hidden;
		text-align: center;
		@include media(">=l") {
			bottom: 80px;
			height: auto;
			margin-left: -25px;
			margin-top: 0;
			top: auto;
			transform: rotate(90deg);
			transform-origin: 50%;
		}
	}

	&__time {
		height: 50px;
		transform: rotate(-90deg);
		width: 50px;
	}

	&__meter,
	&__value {
		fill: rgba(#fff, 0.05);
		stroke: rgba(#fff, 0.3);
		stroke-width: 2;
	}

	&__value {
		fill: none;
		stroke: #fff;
		stroke-linecap: round;
	}
}


/**
 * Software
 */
.o-software {
	//@include fluid-type(margin-top margin-bottom, -3rem, -6rem); // u-brutalism
	//@include fluid-type(padding-top padding-bottom, 3rem, 6rem); // u-brutalism
	//overflow: hidden;
	position: relative;

	.o-section {
		position: static;
	}
}


/**
 * Services
 */
.o-services {
	&__header {
		@include media(">=l") {
			width: span(4);
		}
	}

	&__content {
		.o-entries {
			flex-wrap: nowrap;
		}

		.c-entry {
			box-sizing: border-box;

			&:last-of-type {
				margin-right: 0;
			}

			&__wrap {
				@include fluid-type(padding, 1rem, 2rem);
			}
		}
	}

	&__pag {
		position: static;

		.swiper-pagination-bullet-active::before {
			box-shadow: inset 0 0 0 2px #fff;
		}
	}
}


/**
 * References
 */
.o-references {
	@include media(">=m") {
		align-items: center;
		display: flex;
	}

	&__header {
		@include media(">=m") {
			margin-right: gutter();
			padding-bottom: 0;
			width: span(4);
		}
		@include media(">=l") {
			margin-left: span(1 wide);
			margin-right: span(1 wider);
			width: span(3);
		}
	}

	&__content {
		@include media(">=m") {
			width: span(8);
		}
		@include media(">=l") {
			width: span(7);
		}

		.swiper-container {
			width: 100%;

			@include media(">=#{$container}") {
				margin-right: calc(-50vw + #{($container / 2)} + 3px); // 3px (scrollbar width / 2)
				width: auto;
			}
		}

		figure {
			box-shadow: inset 0 0 0 1px color(gray, 300);
			position: relative;
			width: 100%;
			@include media(">=s") {
				max-width: 300px;
			}
			@include media(">=l") {
				max-width: 400px;
			}

			div {
				@include center(center, 70%, 70%);
				align-items: center;
				display: flex;
				justify-content: center;
			}

			figcaption {
				@include absolute(left 0 bottom 0);
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				height: 100%;
				justify-content: center;
				opacity: 0;
				padding: 10%;
				transform: translate3d(0, 100%, 0);
				transition: opacity 0.35s, transform 0.35s;
				width: 100%;

				p {
					&:last-of-type {
						margin-bottom: 0;
					}
				}

				.c-btn {
					padding: 0;
				}
			}

			&:hover {
				figcaption {
					opacity: 1;
					transform: translate3d(0, 0, 0);
				}
			}
		}
	}

	&__pag {
		position: static;
		@include media(">=m") {
			text-align: left;
		}
	}
}


.c-tabs-to-accordion {
	width: 100%;
	@include media(">=m") {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 6rem;
	}

	&__tabs {
		background: color(gray, 300);
		display: none;
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
		text-align: center;
		width: 100%;
		z-index: 1;
		@include media(">=m") {
			display: flex;
			justify-content: space-between;
		}

		li {
			@include fluid-type(font-size, 18px, 20px);
			background: #fff;
			cursor: pointer;
			line-height: 1.4;
			overflow: hidden;
			padding: 1.5rem 0.5em;
			position: relative;
			width: 100%;

			&:not(last-of-type) {
				margin-right: 1px;
			}

			&.-active {
				background: color(primary, base);
				color: #fff;
				display: block;
				transition: 0.2s ease-in;
			}

			small {
				display: block;
				margin: 0.2em auto 0 auto;
				max-width: 80%;
				opacity: 0.8;
			}
		}
	}

	&__content {
		clear: both;
		width: 100%;
		@include media(">=m") {
			margin-left: auto;
			padding: 2rem 0;
			width: span(6);
		}
		@include media(">=l") {
			padding: 4rem 0;
			width: span(4 of 10); // .o-section.-reduced
		}

		& > h3 {
			border-top: 1px solid color(gray, 300);
			cursor: pointer;
			display: block;
			margin: 0 -#{$container-side};
			padding: 1.5rem gutter();
			user-select: none;

			@include media(">=m") {
				display: none;
				margin: 0;
			}

			&.-active {
				background: color(primary, base);
				color: #fff;
			}
		}
	}
}


.c-software {
	position: relative;

	@include media(">=m") {
		color: #fff;
	}

	&__text {
		max-width: 660px;
		position: relative;
		z-index: 1;

		h2 {
			display: none;
			@include media(">=m") {
				color: #fff;
				display: block;
			}
		}
	}

	&__media {
		bottom: 0;
		position: static;
		top: 0;

		@include media(">=m") {
			left: 0;
			position: absolute;
			transform: scaleX(-1);
			width: 100%;

			&::before {
				@include absolute(top 0 left 0, 0);
				background: linear-gradient(
								to top left,
								hsla(330, 0%, 0%, 0.65),
								ease-in-out,
								hsla(210, 100%, 0%, 0.2)
				); // https://larsenwork.com/easing-gradients/
				content: '';
				display: block;
				height: 100%;
				width: 100%;
			}
		}
	}
}
