////
/// ============================================================================
/// COMPONENT / Card
/// ============================================================================
///
/// @group components
////

%c-card,
.c-card {
    background-color: transparent;
    border-radius: $border-radius;
    box-shadow: inset 0 0 0 1px color(gray, 300);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: gutter();
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    &.-large {
        padding: 1rem;
    }

    &__link {
        @include absolute(top 0 left 0, 1);
        height: 100%;
        width: 100%;

        &:hover {
            & + div h3 {
                color: color(primary, base);
            }

            & + div .c-btn {
                background-color: color(tertiary, base);
                box-shadow: 0 0 0 1px currentColor;
                color: #fff;
                pointer-events: none;
                text-decoration: none;
            }
        }
    }

    &__header {
        padding: 1rem 1rem 0;
        position: relative;
    }

    &__media {
        position: relative;
    }

    &__body {
        flex: 1;
        padding: 1rem;

        & > *:first-child {
            margin-top: 0;
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }
}

.c-flex {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    &__media {
        margin-right: 0.5em;
    }

    &__content {
        margin-bottom: 0;

        small {
            display: block;
            font-size: 0.7em;
            opacity: 0.6;
        }
    }
}
