////
/// ============================================================================
/// VARIABLES
/// ============================================================================
///
/// @group config
////

/**
 * Debug
 */
$debug: false;


/// Font Weight
/**
 * Font Weight
 *
 * @see https://www.webtype.com/info/articles/fonts-weights/
 */
$font-weight-extra-light: 100 !global;
$font-weight-light: 200 !global;
$font-weight-book: 300 !global;
$font-weight-regular: 400 !global;
$font-weight-medium: 500 !global;
$font-weight-semibold: 600 !global;
$font-weight-bold: 700 !global;
$font-weight-black: 900 !global;
$font-weight-extra-black: 900 !global;


/**
 * Breakpoints & Grid
 * 
 * - s .......... 480px Default for extra small devices, phone
 * - m .......... 768px Default for small devices, tablets
 * - l .......... 992px Default for medium devices, desktops
 * - xl ......... 1200px Default for large devices, wide screens
 *
 * Per ciascun breakpoint vengono settati:
 *
 * - query ...... media query
 * - susy ....... personalizzazioni della griglia
 */
$susy: (
    'columns': susy-repeat(12),
    'gutters': 0.4,
    'spread': 'narrow',
    'container-spread': 'narrow',
    'svg-grid-colors': hsl(180, 50%, 80%)
);

$container: 1440px;
$container-side: 1em;
$scrollbar-width: 6px;


/**
 * Breakpoint
 *
 * @link https://include-media.com/
 */
$breakpoints: (
    xs: 0,
    s: 540px,
    m: 900px,
    l: 1100px,
    xl: 1370px
) !global;


/// Mappa colori
///
/// @prop {Color} body          [#fff]      - body
/// @prop {Color} text          [#767677]   - text
/// @prop {Color} gray-darker   [#1e1e1f]   - gray darker
/// @prop {Color} gray-dark     [#767677]   - gray dark
/// @prop {Color} gray          [#b1b0af]   - gray
/// @prop {Color} gray-light    [#c2c0c0]   - gray light
/// @prop {Color} gray-lighter  [#d4d3d2]   - gray lighter
/// @prop {Color} success       [#5cb85c]   - success
/// @prop {Color} info          [#5bc0de]   - info
/// @prop {Color} warning       [#f0ad4e]   - warning
/// @prop {Color} danger        [#d9534f]   - danger
/// @prop {Color} primary       [#222838]   - primary
/// @prop {Color} secondary     [#e31a4e]   - secondary
/// @prop {Color} tertiary      [#71bdb4]   - tertiary
///
/// @example scss - Usage
/// .foo {
///     color: color-get(body);
/// }
/// .bar {
///     color: color-get(gray, 0.5, -10%);
/// }
///
/// @example css - CSS Output
/// .foo {
///     color: #fff;
/// }
/// .bar {
///     color: hsla(0, 0%, 52%, .5);
/// }
///
/// @type Map
$colors: (
    body            : #fff,
    text            : (
        base        : #19171c,
        light       : lighten(#12110c, 20%),
        dark        : darken(#12110c, 20%)
    ),

    gray            : (
        100         : #f3f3f3, // Light Background
        200         : #f8f8f8, // Background
        300         : #dbdbdb, // Border
        400         : #c3c3c3, // Excerpt
        500         : #393939, // Subtitle
        600         : #19171c, // Title
    ),

    success         : #21d492,
    info            : #3d9cd2,
    warning         : #fcd34a,
    error           : #fa3d56,

    primary         : (
        base        : #de041c,
        light       : lighten(#de041c, 10%),
        dark        : darken(#de041c, 10%),
    ),
    secondary       : (
        base        : #19171c,
        light       : lighten(#19171c, 10%),
        dark        : darken(#19171c, 10%)
    ),
    tertiary        : (
        base        : pink,
        light       : lighten(pink, 10%),
        dark        : darken(pink, 10%)
    ),
) !global;


/**
 * Links
 */
$link-color                 : color(primary, base) !global;
$link-hover-color           : color(primary, dark) !global;
$link-visited-color         : $link-hover-color !global;
$link-decoration            : underline !global;
$link-hover-decoration      : underline !global;
$link-visited-decoration    : underline !global;


/**
 * Border radius
 */
$border-radius: 6px !global;


/**
 * Buttons
 */
$btn-default-font-size: 16px !global;
$btn-default-border-radius: 0 !global;
$btn-default-padding: 1em 2.5em !global;
$btn-default-text-trasform: none !global;


/**
 * Form
 */
$form-item-margin: 1.25rem !global;

$label-text-color: #999 !global;

$input-background-color: #fff;
$input-border-color: color(text, light) !global;
$input-icon-color: color(text, base) !global;
$input-text-color: color(text, base) !global;
$input-placeholder-color: color(text, light) !global;
$input-padding: 0.8em 1em !global;

$checkbox: rem(18px) !global;
$checkbox-icon-color: $input-icon-color !global;
$radio-icon-color: $input-icon-color !global;

$select-icon: 30px !global;
$select-icon-color: color(text, base) !global;


/**
 * Header
 */
$f-header-mobile-height: 60px;
$f-header-desktop-height: 120px;
