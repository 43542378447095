////
/// ============================================================================
/// COMPONENT / Search
/// ============================================================================
///
/// @group components
////

.c-search {
}


/**
 * Search Form
 */
.c-search-form {
    align-items: center;
    background: #fff;
    border-radius: $border-radius;
    display: flex;
    margin: 0;
    padding: 0.3rem;

    &__label {
        display: none;
        margin-bottom: 0;
        margin-right: 0.5em;
        max-width: 40px;
        min-width: 24px;
        text-align: center;
        width: 100%;

        .c-hero & {
            display: block;
        }
    }

    &__query {
        border: 0;
        flex: 1;
        padding: 0.5em 0;
    }

    &__button {
        .c-hero & {
            svg {
                @include hide(hidden);
            }

            span {
                margin-left: 0;
            }
        }
    }
}


/**
 * Search Example
 */
.c-search-example {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    &__item {
        &:not(:last-of-type) {
            margin-right: 1em;
        }
    }
}