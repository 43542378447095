////
/// ============================================================================
/// UTILITY / Shape
/// ============================================================================
///
/// @group utilities
////

// Chevron
%chevron {
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: block;
    height: 10px;
    margin-top: -5px;
    position: relative;
    top: 50%;
    transform: rotate(135deg);
    transform-origin: center;
    vertical-align: top;
    width: 10px;
}
