////
/// ============================================================================
/// GENERIC / Form
///
/// @see dist/css/bootstrap-reboot.css
/// ============================================================================
///
/// @group generics
////

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    font-size: inherit;
    line-height: inherit;
}

#{text-inputs()},
select,
textarea {
    outline: 0;
}

select {
    text-transform: none;
    word-wrap: normal;

    &::-ms-expand {
        display: none;
    }

    &::-ms-value {
        background: none;
        color: inherit;
    }
    &::-ms-clear {
        display: none; // hide X icon in IE and Edge
    }
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
}

[type="search"] {
    -webkit-appearance: none;
}