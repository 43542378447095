////
/// ============================================================================
/// COMPONENT / Modal
/// ============================================================================
///
/// @group components
////

.modal {
    @include fixed(top 0 left 0, 999);
    align-items: center;
    background-color: hsla(240, 1%, 60%, 0.88);
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;

    // hide element
    pointer-events: none;
    visibility: hidden;
    width: 100%;

    &--is-visible {
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
    }

    &__content {
        background-color: hsl(0, 0%, 100%);
        border-radius: 0.25em;
        max-height: calc(100vh - 4 * (1.25em + calc(1.25 * 1.25em)));
        overflow: auto;
        width: calc(100% - 2 * calc(1.25 * 1.25em));
    }

    &__header {
        align-items: center;
        background-color: hsl(0, 0%, 100%);;

        // align title and icon (if icon is placed inside header)
        display: flex;
        justify-content: space-between;
        padding: calc(1.25 * 1.25em) calc(1.25 * 1.25em);
    }

    &__body {
        height: 100%;
        padding: calc(1.25 * 1.25em) calc(1.25 * 1.25em);
    }

    &__footer {
        padding: calc(1.25 * 1.25em);
    }

    &__close-btn {
        @include fixed(top $container-side right $container-side, 5);
        background-color: color(primary, base);
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        padding: 1em;
        z-index: 5;

        &:hover {
            background-color: color(primary, dark);
        }

        svg {
            color: hsl(0, 0%, 100%);
            display: block;
            height: 1.25em;
            width: 1.25em;
        }
    }

    // if close btn is inside content (e.g, inside header)
    &__content {
        .modal__close-btn { // alt close btn style (if inside modal content)
            background-color: hsl(0, 0%, 100%);
            box-shadow: 0 1px 2px rgba(0, 0, 0, .085), 0 1px 8px rgba(0, 0, 0, .1);
            flex-shrink: 0;
            position: static;
            transition: .2s;

            .icon {
                color: inherit;
            }

            &:hover {
                box-shadow: 0 1px 8px rgba(0, 0, 0, .085), 0 8px 24px rgba(0, 0, 0, .1);

                svg {
                    color: hsl(0, 0%, 100%);;
                }
            }
        }
    }

    // close btn sticky within content
    &__content {
        .modal__close-btn--sticky {
            float: right;
            position: sticky;
            right: 0;
            top: 0;
        }
    }

    // --header-is-fixed
    &--header-is-fixed {
        .modal__header {
            position: sticky;
            top: 0;
        }
    }

    // --footer-is-fixed
    &--footer-is-fixed {
        .modal__footer {
            background: hsl(0, 0%, 100%);
            bottom: 0;
            box-shadow: 0 1px 2px rgba(0, 0, 0, .085), 0 1px 8px rgba(0, 0, 0, .1);
            position: sticky;
        }
    }

    // if animation = enabled
    &[data-animation="on"] {
        transition: opacity .3s, visibility .3s;

        .modal__content {
            transform: translateY(10%);
            transition: transform .3s cubic-bezier(0.215, 0.61, 0.355, 1);
            will-change: transform;
        }

        &.modal--is-visible .modal__content {
            transform: translateY(0);
        }
    }

}