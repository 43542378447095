/* --------------------------------

File#: _1_menu
Title: Menu
Descr: Application menu that provides access to a set of functionalities
Usage: codyhouse.co/license

-------------------------------- */

.menu {
    background-color: #fff;
    border-radius: $border-radius;
    box-shadow: rgba(#000, 0.08) 0 1px 2px 0, rgba(#000, 0.1) 0 1px 8px 0;
    font-size: 1rem;
    list-style: none;
    margin-bottom: 4px;
    margin-top: 4px;
    opacity: 0;
    overflow: auto;
    padding: 0.5em 0;
    position: fixed; // top/left position set in JS
    transition: visibility 0s .2s, opacity .2s;
    user-select: none;
    visibility: hidden;
    width: 220px;
    z-index: 5;
}

.menu--is-visible {
    opacity: 1;
    transition: visibility 0s, opacity .2s;
    visibility: visible;
}

.menu--overlay {
    z-index: 15;
}

.menu__content {
    align-items: center;
    color: color(text, base);
    cursor: pointer;
    display: block; // fallback
    display: flex;
    overflow: hidden;
    padding: 0.5em 1em;
    text-decoration: none; // reset link style
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        background-color: color(gray, 100);
    }

    &:focus {
        background-color: rgba(color(primary, base), 0.1);
        outline: none;
    }
}

.menu__label {
    color: color(text, light);
    font-size: 0.8em;
    padding: 0.5em 1em;
}

.menu__separator {
    background-color: color(gray, 300);
    height: 1px;
    margin: 0.5em 1em;
}

.menu__icon {
    margin-right: 0.5em;
    opacity: 0.7;
}