////
/// ============================================================================
/// COMPONENT / Button
/// ============================================================================
///
/// @group components
////

%reset-btn {
    appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    line-height: inherit;
    outline: 0;
    padding: 0;
    text-decoration: none;
    user-select: none;
}

%c-btn,
.c-btn {
    @extend %reset-btn;
    @include fluid-type(padding-top padding-bottom, 1em, 1.5em);
    @include fluid-type(padding-left padding-right, 1em, 2.5em);
    align-items: center;
    border-radius: $btn-default-border-radius;
    display: inline-flex;
    font-size: $btn-default-font-size;
    font-weight: $font-weight-semibold;
    justify-content: center;
    line-height: 1;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: $btn-default-text-trasform;
    transition: all 0.25s;
    transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
    vertical-align: middle;
    white-space: nowrap;

    // Color
    & {
        background-color: color(primary, base);
        color: #fff;

        &:hover {
            background-color: color(primary, light);
            color: #fff;
            text-decoration: none;
        }

        &:focus {
            outline: 0;
        }
    }

    & > *:nth-child(2) {
        margin-left: 0.5em;
    }

    &.disabled,
    &:disabled {
        background-color: color(gray, 400);
        box-shadow: none;
        color: #fff;
        cursor: not-allowed;

        &:hover {
            background-color: color(gray, 400);
            box-shadow: none;
            color: #fff;
        }
    }

    svg {
        flex-shrink: 0;
        transform: scale(1.2);
        transform-origin: center;
    }

    &.-expand {
        width: 100%;
    }

    &.-small {
        font-size: $btn-default-font-size - 1px;
        padding: 0.7em 1.2em;
    }

    &.-negative {
        background-color: #fff;
        color: color(primary, base);

        &:hover {
            background-color: transparent;
            box-shadow: inset 0 0 0 1px #fff;
            color: #fff;
        }
    }

    &.-ghost {
        background-color: transparent;
        color: color(primary, base);

        &:hover {
            background-color: transparent;
            color: rgba(color(primary, base), 0.5);
        }

        &.-negative {
            background-color: transparent;
            color: #fff;

            &:hover {
                background-color: transparent;
                box-shadow: none;
                color: rgba(#fff, 0.5);
            }
        }
    }

    &.-outline {
        background-color: transparent;
        box-shadow: inset 0 0 0 1px color(gray, 300);
        color: color(text, base);

        &:hover {
            background-color: transparent;
            box-shadow: inset 0 0 0 1px color(primary, base);
            color: color(primary, base);
        }

        &.-negative {
            background-color: transparent;
            box-shadow: inset 0 0 0 1px #fff;
            color: #fff;

            &:hover {
                background-color: transparent;
                box-shadow: inset 0 0 0 1px rgba(#fff, 0.5);
                color: rgba(#fff, 0.5);
            }
        }
    }
}
