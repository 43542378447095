////
/// ============================================================================
/// GUTENBERG - COLORS
/// ============================================================================
///
/// @group vendors/gutenberg
////

.has-primary {
	&-background-color {
		background-color: color(primary, base);
	}

	&-color {
		color: color(primary, base);
	}
}

.has-gray-100 {
	&-background-color {
		background-color: color(gray, 100);
	}

	&-color {
		background-color: color(gray, 100);
	}
}

.has-gray-200 {
	&-background-color {
		background-color: color(gray, 200);
	}

	&-color {
		background-color: color(gray, 200);
	}
}

.has-highlight {
	&-background-color {
		background-color: #89cfef;
	}

	&-color {
		color: #89cfef;
	}
}
