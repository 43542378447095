////
/// ============================================================================
/// COMPONENT / Table
/// ============================================================================
///
/// @group components
////

.c-table {
    border-bottom: 2px solid color(gray, 300);
    overflow-x: auto;
    width: 100%;

    table {
        border-collapse: collapse;
        margin: 0;
        max-width: 100%;
        vertical-align: top;
        width: 100%;
    }

    th,
    td {
        padding: 0.5rem;
        text-align: left;
        white-space: nowrap;
        @include media(">=m") {
            white-space: normal;
        }
    }

    th {
        font-weight: bold;
    }

    &__header {
        background: #fff;
        border-bottom: 2px solid color(gray, 300);
        tr {
            border-bottom: 2px solid color(gray, 400);
        }
    }

    &__body {
        tr {
            &:nth-child(odd) {
                background: color(gray, 200);
            }
        }
    }
}