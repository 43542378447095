////
/// ============================================================================
/// COMPONENT / Social
/// ============================================================================
///
/// @see https://brandcolors.net/
///
/// @group components
////

$social-brand: (
    facebook: #3b5998,
    linkedin: #0077b5,
    rss: #f62,
    whatsapp: #25d366,
    youtube: #f00,
);

$social-default: color(gray, 400);

.c-social {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    &__item {
        a,
        button {
            @extend %reset-btn;
            margin: 0;
        }
    }

    &.-icons {
        .c-social__item {
            align-items: center;
            display: flex;
            flex-direction: column;
            text-align: center;

            a,
            button {
                align-items: center;
                background: $social-default;
                color: #fff;
                display: flex;
                justify-content: center;
                text-align: center;

                &:hover {
                    background: darken($social-default, 10%);
                }
            }

            @each $name, $color in $social-brand {
                &.#{$name} a,
                &.#{$name} button {
                    background: $color;

                    &:hover {
                        background: darken($color, 10%);
                    }
                }
            }
        }
    }

    // Share
    &.-share {
        align-items: center;
        justify-content: center;

        .c-social__item {
            &:not(:last-of-type) {
                margin-bottom: 3px;
            }

            a,
            button {
                border-radius: 10em;
                display: block;
                height: 3em;
                width: 3em;
            }

            &.whatsapp {
                @include media(">=m") {
                    display: none;
                }

                &.-web {
                    display: none;
                    @include media(">=m") {
                        display: flex;
                    }
                }
            }
        }
    }

    // Follow Us
    &.-follow {
        .c-social__item {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 3px;
            text-align: center;

            a,
            button {
                align-items: center;
                border: 1px solid currentColor;
                color: #fff;
                display: flex;
                height: 40px;
                justify-content: center;
                text-align: center;
                width: 40px;
            }

            @each $name, $color in $social-brand {
                &.#{$name} a,
                &.#{$name} button {
                    &:hover {
                        background: #fff;
                        border-color: #fff;
                        color: $color;
                    }
                }
            }
        }
    }
}


.c-share {
    overflow: hidden;
    position: relative;
    transition: .3s;

    & > span {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        transition: all .3s ease-in-out;
        width: 100%;
    }

    &__icon {
    }

    &__count {
        @include absolute(bottom -100% left 0);
        color: #fff;
        font-size: 20px;
    }

    &[class*="js-"]:hover {
        .c-share__icon {
            transform: translateY(-100%);
        }

        .c-share__count {
            bottom: 0;
        }
    }
}

.c-social-count {
    padding: 0.5em;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    @include media(">=m") {
        width: auto;
    }

    &__total {
        font-size: 2em;
        line-height: 1;
    }

    &__text {
        font-size: 12px;
        opacity: 0.6;
    }
}

.c-sharing {
    @include fixed(bottom 1rem right 1rem, 99);
    transform: translateY(100px);
    transition: transform 0.5s;
    width: auto;
    .js & {
        &.is-visible {
            transform: translateY(0);
        }
    }
    @include media(">=l") {
        position: static; // Reset
        position: sticky;
        transform: none;
        top: 6rem;
    }

    &__social {
        flex: 1 1 auto;
        width: auto;
    }
}
