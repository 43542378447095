////
/// ============================================================================
/// UTILITY / Border
/// ============================================================================
///
/// @group utilities
////

.u-border {
    border-color: color(gray, 300);
    border-style: solid;
    border-width: 0;

    &.-top {
        border-top-width: 1px;
    }
    &.-right {
        border-right-width: 1px;
    }
    &.-bottom {
        border-bottom-width: 1px;
    }
    &.-left {
        border-left-width: 1px;
    }

    &.-horizontal {
        border-bottom-width: 1px;
        border-top-width: 1px;
    }
    &.-cover {
        border-width: 1px;
    }
}