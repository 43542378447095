////
/// ============================================================================
/// COMPONENT / Video
/// ============================================================================
///
/// @group components
////

.c-video-playlist {
    @include media(">=l") {
        align-items: center;
        display: flex;
    }

    .c-video {
        margin-bottom: 1.5rem;
        @include media(">=l") {
            flex-grow: 1;
            margin-bottom: 0;
            margin-right: 1em;
        }
    }
}


.c-video {
    background: #000;
    position: relative;
    width: 100%;

    &__play {
    }

    &__image {
        cursor: pointer;

        img {
            opacity: 0.8;
        }
    }

    &__iframe {
        z-index: 0;
    }

    &__image,
    iframe {
        @include absolute(top 0 left 0);
        height: 100%;
        width: 100%;
    }
}


/**
 * Play
 */
.c-play {
    @extend %reset-btn;
    @include center(center, 40px, 40px, 10);
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    text-align: center;
    transform: translateY(0) scale(1);
    transition: transform 0.9s ease 0.35s, opacity 0.9s ease 0.35s;

    svg {
        height: 20px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate3d(-42%, -50%, 0);
        transition: color 0.3s ease;
    }

    &::before {
        background-color: rgba(#000, .3);
        border-color: #fff;
        border-radius: 100%;
        border-style: solid;
        border-width: 2px;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: background-color .3s ease, transform .3s ease, border-color .3s ease;
        width: 100%;
    }

    &:hover {
        color: color(primary, base);

        &::before {
            background-color: rgba(#000, .8);
            border-color: color(primary, base);
            transform: scale(1.2);
        }
    }

    &.-large {
        height: 60px;
        margin-left: -30px;
        margin-top: -30px;
        width: 60px;

        svg {
            height: 20px;
            width: 20px;
        }

        @include media(">=m") {
            height: 80px;
            margin-left: -40px;
            margin-top: -40px;
            width: 80px;
            &::before {
                border-width: 3px;
            }
            svg {
                height: 34px;
                width: 34px;
            }
        }
        @include media(">=l") {
            height: 120px;
            margin-left: -60px;
            margin-top: -60px;
            width: 120px;
            svg {
                height: 42px;
                width: 42px;
            }
        }
    }
}


.c-playlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    @include media(">=l") {
        max-width: 420px;
    }

    &__item {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        padding: 0.8em;
        transition: box-shadow 0.3s ease;
        width: 100%;
        @include media(">=m") {
            flex-direction: column;
            margin: 0.5em;
            max-width: 200px;
        }
        @include media(">=l") {
            align-items: center;
            display: flex;
            flex-direction: row;
            max-width: none;
        }

        &.is-active {
            background: #fff;
            border-radius: $border-radius;
            box-shadow: inset 0 0 0 1px color(gray, 300);
        }

        div {
            flex: 0 0 auto;
            width: 30%;
            @include media(">=m") {
                width: 100%;
            }
            @include media(">=l") {
                width: 120px;
            }
        }

        figcaption {
            flex: 1;
            padding-left: 1em;
            text-align: left;
            word-break: break-word;
            @include media(">=m") {
                padding-left: 0;
                padding-top: 0.5rem;
                text-align: center;
            }
            @include media(">=l") {
                padding-left: 1em;
                padding-top: 0;
                text-align: left;
            }
        }
    }
}
