////
/// ============================================================================
/// UTILITY / Spacing
/// ============================================================================
///
/// @group utilities
////

$spacings: xs, s, m, l, xl, xxl;

@each $spacing in $spacings {
    $i: index($spacings, $spacing);

    $spacing-min: $i * 0.5rem;
    $spacing-max: $i * 1rem;

    .u-leading-#{$spacing} {
        @include fluid-type(padding-top, $spacing-min, $spacing-max);
    }

    .u-trailing-#{$spacing} {
        @include fluid-type(padding-bottom, $spacing-min, $spacing-max);
    }

    .u-side-#{$spacing} {
        @include fluid-type(padding-bottom padding-top, $spacing-min, $spacing-max);
    }

    .u-padding-#{$spacing} {
        @include fluid-type(padding, $spacing-min, $spacing-max);
    }
}
