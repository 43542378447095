////
/// ============================================================================
/// BASE / Typography
/// ============================================================================
///
/// @group base
////

/**
 * Links
 */
a {
	color: $link-color;
	text-decoration: $link-decoration;
	transition: all 0.25s;
	transition-timing-function: cubic-bezier(0, 0, 0.58, 1);

	&:hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}
}


/**
 * Headings
 */
#{headings(1, 6)},
.h1, .h2, .h3, .h4, .h5, .h6 {
	color: color(text, dark);
	line-height: 1.3;
	margin: 0 0 1rem 0;
}

.h1, h1 {
	@include fluid-type(font-size, 40px, 66px);
	line-height: 1;
}

.h2, h2 {
	@include fluid-type(font-size, 30px, 40px);
	line-height: 1.1;
}

.h3, h3 {
	@include fluid-type(font-size, 24px, 30px);
}

.h4, h4 {
	@include fluid-type(font-size, 18px, 24px);
}

.h5, h5,
.h6, h6 {
	@include fluid-type(font-size, 14px, 16px);
}

.u-type {
	line-height: 1.2;

	&.-xs {
		@include fluid-type(font-size, 12px, 14px);
	}

	&.-s {
		@include fluid-type(font-size, 14px, 16px);
	}

	&.-m {
		@include fluid-type(font-size, 18px, 22px);
		line-height: 1.4;
	}

	&.-l {
		@include fluid-type(font-size, 24px, 30px);
	}
}

.c-heading {
	margin-bottom: 0;

	.o-page & {
		@include media(">=l") {
			max-width: $container;
			width: span(8 of 10);
		}
	}
}

p.c-heading {
	@extend .u-type.-m;
	opacity: 0.8;
}

p:empty {
	display: none;
}


/**
 * Standard
 */
blockquote,
dl,
form,
ol,
p,
pre,
table,
ul {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

ul, ol {
	padding-left: 1em;
}

dfn,
cite,
em,
i {
	font-style: normal;
}

mark {
	background-color: transparent;
	color: #fff;
	font-weight: $font-weight-semibold;
}


/**
 * Navigation
 */
nav {
	ul, ol {
		@include reset(list);
	}

	a, a:hover {
		text-decoration: none;
	}
}


/**
 * Citation
 */
blockquote {
	margin-left: 0;
	margin-right: 0;
	padding: 0 0 0 1rem;
	position: relative;

	@include media(">=l") {
		padding-left: 2rem;
	}

	&::before {
		@include absolute(top 0 left 0);
		background: color(primary, base);
		content: '';
		display: block;
		height: 100%;
		width: 8px;
	}

	p {
		&:first-of-type {
			margin-top: 0;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}


/**
 * Address
 */
address {
	font-style: inherit;
}


/**
 * Hr
 */
hr {
	border: 0;
	border-top: 1px solid #ccc;
	display: block;
	height: 1px;
	margin: 1em 0;
	padding: 0;
}
