////
/// ============================================================================
/// BLOG
/// ============================================================================
///
/// @group templates
////

body.blog {
    .f-header:not(.hide-nav--has-bg) {
        background: color(secondary, base);

        .c-logo {
            color: #fff;
        }

        .f-header__list > .f-header__item {
            > .f-header__link {
                color: #fff;

                .is-active > &,
                &:hover {
                    color: #fff;
                }
            }

            &.button {
                a {
                    @extend %c-btn;
                    background-color: transparent;
                    box-shadow: inset 0 0 0 1px #fff;
                    color: #fff;

                    &:hover {
                        background-color: transparent;
                        box-shadow: inset 0 0 0 1px #fff;
                        color: #fff;
                    }
                }
            }
        }

        .anim-menu-btn__icon {
            background-image: linear-gradient(#fff, #fff);
        }
    }

    .o-page__header,
    .o-archive__header {
        margin-top: $f-header-mobile-height;
        @include media(">=m") {
            margin-top: $f-header-desktop-height;
        }
    }

    &.admin-bar .o-page__header,
    &.admin-bar .o-archive__header {
        margin-top: $f-header-mobile-height - 32px; // wp admin-bar
        @include media("<=782px") {
            margin-top: $f-header-mobile-height - 46px; // wp admin-bar
        }
        @include media(">=m") {
            margin-top: $f-header-desktop-height - 32px; // wp admin-bar
        }
    }

    .o-archive__title {
        .c-dropdown {
            position: relative;
            @include media(">=m") {
                $spacing: 5 * 0.5rem;
                $breakpointS: map-get($breakpoints, s);
                $breakpointXl: map-get($breakpoints, xl);
                bottom: calc(-1 * (#{$spacing} + #{strip-units($spacing)} * (100vw - #{$breakpointS}) / #{(strip-units($breakpointXl) - strip-units($breakpointS))}));
                padding-top: 0;
            }
            @include media(">=xl") {
                bottom: -5 * 1rem;
            }
        }
    }
}
