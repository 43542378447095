////
/// ============================================================================
/// GUTENBERG - COVER
/// ============================================================================
///
/// @group vendors/gutenberg
////

.wp-block-cover {
    &__inner-container {
        & > h2,
        & > p {
            @include media(">=m") {
                padding-right: span(2 wide);
            }
            @include media(">=l") {
                padding-right: span(4 wide);
            }
        }

        & > h2 {
            margin-bottom: 2rem;
        }
    }
}
