////
/// ============================================================================
/// COMPONENT / Alerts
/// ============================================================================
///
/// @group components
////

%c-alert,
.c-alert {
    background: color(gray, 200);
    border: 1px solid color(gray, 300);
    padding: 1rem 1.5em;
    position: relative;
    width: 100%;

    &, a {
        color: color(text, base);
    }

    ul, ol {
        list-style: none;
        padding: 0;
    }

    $alerts: success, info, warning, error;

    @each $alert in $alerts {
        $color: color($alert);

        &.-#{$alert} {
            background: scale-color($color, $lightness: 50%);
            border-color: $color;
            color: darken($color, 30%);
        }
    }

    > :first-child {
        margin-top: 0;
    }
    > :last-child {
        margin-bottom: 0;
    }
}