/* --------------------------------

File#: _2_menu-bar
Title: Menu Bar
Descr: Application menu with a list of common actions that users can perform
Usage: codyhouse.co/license

-------------------------------- */


.menu-bar {
    align-items: center;
    display: inline-flex;
    list-style: none;
}

.menu-bar__item { // menu button
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block; // flex fallback
    display: flex;
    height: 2.5em;
    justify-content: center;
    position: relative;
    transition: background-color .2s;
    width: 2.5em;

    &:not(:last-child) {
        margin-right: 0.5em;
    }

    &:hover,
    &.menu-control--active {
        background-color: color(gray, 100);

        > .menu-bar__icon {
            color: color(text, base);
        }

        > .menu-bar__label { // show label
            clip: auto;
            clip-path: none;
            height: auto;
            width: auto;
        }
    }

    &:focus {
        background-color: rgba(color(primary, base), 0.1);
        outline: none;
    }

    &:active {
        background-color: color(gray, 300);
    }

    &:focus:active {
        background-color: rgba(color(primary, base), 0.2);
    }
}

.menu-bar__item--trigger { // button used to show hidden actions - visibile only if menu = collapsed
    display: none;
}

.menu-bar__icon {
    color: color(text, base);
    display: block;
    font-size: 1em; // set icon size
    transition: color .2s;
}

.menu-bar__label { // label visible on :hover
    // hide
    background-color: rgba(color(text, base), 0.95);
    border-radius: $border-radius;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    color: #fff;
    font-size: 0.6em;
    -webkit-font-smoothing: antialiased;
    // style
    height: 1px;
    left: 50%;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    padding: 0.5em 0.5em;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transform: translateX(-50%) translateY(4px);
    user-select: none;
    white-space: nowrap;
    width: 1px;
}

.menu-bar--collapsed { // mobile layout style
    .menu-bar__item--hide { // hide buttons
        display: none;
    }

    .menu-bar__item--trigger { // show submenu trigger
        display: inline-block; // flex fallback
        display: flex;
    }
}

// detect when the menu needs to switch from the mobile layout to an expanded one - used in JS
.js {
    .menu-bar {
        opacity: 0; // hide menu bar while it is initialized in JS

        &::before {
            content: 'collapsed';
            display: none;
        }
    }

    .menu-bar--loaded {
        opacity: 1;
    }

    @each $breakpoint in map-keys($breakpoints) {
        @include media(">=#{$breakpoint}"){
            .menu-bar--expanded\@#{$breakpoint}::before {
                content: 'expanded';
            }
        }
    }
}
