////
/// ============================================================================
/// COMPONENT / Entry
/// ============================================================================
///
/// @group components
////

.c-entry {
    @include fluid-type(margin-top margin-bottom, 1rem, 1.5rem);
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    width: 100%;

    .o-archive & {
        @include fluid-type(margin-top margin-bottom, 1rem, 2rem);
    }

    &__link {
        &:hover + .c-entry__media {
            filter: saturate(1);

            & + .c-entry__wrap h3,
            & + .c-entry__wrap .c-btn {
                color: $link-color;
            }
        }
    }

    &__media {
        align-items: center;
        display: flex;
        filter: saturate(0.8);
        overflow: hidden;
        position: relative;
        width: 100%;

        figure {
            overflow: hidden;
            position: relative;
            width: 100%;
            z-index: 1;
        }

        img {
            @include transform(null, 0);
        }
    }

    &__wrap {
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        justify-content: center;
        padding: 1rem 1em;
        width: 100%;
        @include media(">=s") {
            padding-bottom: 0;
            padding-right: 0;
            padding-top: 0;
        }
    }

    &__header {
        .c-list {
            margin-bottom: 0.5rem;
            position: relative;
            z-index: 5;
        }

        h3 {
            margin-bottom: 0;

            .c-content & {
                margin-top: 0; // Reset .c-content
            }
        }
    }

    &__body {
        margin-top: 1rem;

        p {
            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__footer {
        .c-btn.-ghost {
            padding: 0;
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @include media(">=#{$breakpoint}") {
            &.-portrait\@#{$breakpoint} {
                flex-direction: column;

                .c-entry__wrap {
                    padding: 1.5rem;
                    width: 100%;
                }

                .c-entry__media {
                    width: 100%;
                }
            }
        }
    }
}


.c-product {
    @include fluid-type(margin-top margin-bottom, 2rem, 6rem);
    overflow: hidden;
    position: relative;
    width: 100%;

    @include media(">=m") {
        &::before {
            content: '';
            display: block;
            padding-bottom: percentage(9 / 21); // ratio 21:9
            width: 100%;
        }
    }

    &::after {
        background: #fff;
        content: '';
        height: 2rem;
        position: absolute;
        width: 50%;
        z-index: 1;
        @include media(">=s") {
            height: 3rem;
        }
        @include media(">=m") {
            height: 4rem;
        }
        @include media(">=l") {
            height: 5rem;
        }
        @include media(">=xl") {
            height: 6rem;
        }
    }

    &:nth-of-type(even) {
        &::after {
            bottom: 0;
            right: 0;
        }
    }

    &:nth-of-type(odd) {
        &::after {
            left: 0;
            top: 0;
        }

        .c-product__content {
            padding-bottom: 2rem;
            padding-top: 2rem * 2;
            @include media(">=s") {
                padding-bottom: 3rem;
                padding-top: 3rem * 2;
            }
            @include media(">=m") {
                padding-bottom: 4rem;
                padding-top: 4rem * 2;
            }
            @include media(">=l") {
                padding-bottom: 5rem;
                padding-top: 5rem * 2;
            }
            @include media(">=xl") {
                padding-bottom: 6rem;
                padding-top: 6rem * 2;
            }
        }
    }

    &__link {
        z-index: 10;

        &:hover {
            & + .c-product__content .c-btn {
                background-color: color(primary, light);
            }
        }
    }

    &__content {
        @include fluid-type(padding-left padding-right, 2rem, 6rem);
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 2rem * 2;
        padding-top: 2rem;
        position: relative;
        z-index: 1;
        @include media(">=s") {
            padding-bottom: 3rem * 2;
            padding-top: 3rem;
        }
        @include media(">=m") {
            @include absolute(top 0 left 0);
            height: 100%;
            max-width: 60%;
            padding-bottom: 4rem * 2;
            padding-top: 4rem;
            width: 100%;
        }
        @include media(">=l") {
            padding-bottom: 5rem * 2;
            padding-top: 5rem;
        }
        @include media(">=xl") {
            padding-bottom: 6rem * 2;
            padding-top: 6rem;
        }
    }

    &__media {
        z-index: 0;
    }
}
