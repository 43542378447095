////
/// ============================================================================
/// GUTENBERG - COLUMNS
/// ============================================================================
///
/// @group vendors/gutenberg
////

.wp-block-column {
    position: relative;
    @include media(">=782px") {
        &:not(:first-child) {
            margin-left: 0;
        }
    }

    @include media(">=m") {
        &:not(:first-child) {
            margin-left: gutter();
        }
    }
}
