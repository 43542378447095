////
/// ============================================================================
/// COMPONENT / Gallery
///
/// @see https://codyhouse.co/ds/components/app/auto-sized-grid
/// ============================================================================
///
/// @group components
////

.c-gallery {
    @include gridAuto(260px, 1em);
    list-style: none;
    margin: 0;
    padding: 0;

    img {
        display: block;
        width: 100%;
    }

    &__item {
        a {
            display: block;
        }
    }
}

.c-image {
    align-items: center;
    border: 1px solid color(gray, 300);
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    min-height: 100px;
    padding: 1rem;
    position: relative;
    transition: filter 0.5s;

    &:hover {
        filter: grayscale(0);

        .c-image__zoom {
            opacity: 1;
        }
    }

    img {
        filter: grayscale(0.5);
    }

    &__caption {
        padding: 0.5rem;
    }

    &__zoom {
        @include center(center, 40px, 40px, 1);
        align-items: center;
        background: #fff;
        border-radius: 50em;
        color: color(text, base);
        display: flex;
        justify-content: center;
        opacity: 0;
        transition: all 0.5s;
    }
}