////
/// ============================================================================
/// BASE / Root
/// ============================================================================
///
/// @link https://prowebtype.com/delivering-web-fonts/
/// @link https://jonsuh.com/blog/font-loading-with-font-events/
///
/// @group base
////

html {
    @include fluid-type(font-size, 15px, 18px);
    box-sizing: border-box;
    line-height: 1.5;
    min-height: 100%;
    //overflow-x: hidden; // Disabilitato per social share sticky
    //overflow-y: auto; // Disabilitato per social share sticky
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow-x: hidden; // .c-organic
    overflow-y: scroll;

    scroll-behavior: smooth;
    @media screen and (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }

    // Scrollbar
    &::-webkit-scrollbar {
        background: #fff;
        width: $scrollbar-width;
    }
    &::-webkit-scrollbar-thumb {
        background: color(primary, base);
    }
}

body {
    background: color(body);
    color: color(text, base);
    height: 100%;
}

::selection {
    background: transparent;
    color: inherit;
    text-shadow: none;
}