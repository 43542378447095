////
/// ============================================================================
/// PAGE
/// ============================================================================
///
/// @group templates
////

.o-page {
	&__header {
		align-items: center;
		display: flex;
		position: relative;
	}

	&__title {
		position: relative;
		z-index: 2;
		@include media(">=m") {
			width: span(10);
		}
		@include media(">=l") {
			width: span(8);
		}
		@include media(">=xl") {
			width: span(6);
		}

		p {
			opacity: 0.9;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&.-center {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__article {
	}

	&__body {
		position: relative;
		@include media(">=l") {
			max-width: span(10);
		}
	}

	&__aside {
		@include media(">=m") {
			margin-left: auto;
			margin-right: auto;
			width: span(8);
		}
		@include media(">=l") {
			width: span(6);
		}

		h3 {
			font-weight: $font-weight-regular;
		}
	}

	&.-post {
		.o-page__body {
			@include media(">=l") {
				align-items: flex-start;
				display: flex;
				justify-content: space-between;
				max-width: span(8);
			}
		}

		.o-page__content {
			@include media(">=l") {
				width: span(7 of 8);
			}
		}

		.c-sharing {
			@include media(">=l") {
				width: span(1 of 8);

				&:not(.is-visible) {
					position: relative;
					top: 7rem;
				}
			}
		}
	}

	&.-software,
	&.-service {
		.o-page__header {
			min-height: 50vh;
		}
	}
}


/**
 * Contenuto del post
 */
.o-content {
	p, ul, ol {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	// Anchor Link Offset
	*[id^="anchor-"]:target {
		position: relative;

		&::before {
			content: "";
			display: block;
			height: 80px;
			margin: -80px 0 0;
		}
	}

	.-post & {
		h2 {
			@extend .u-type.-l;
			font-weight: $font-weight-regular;

			&:not(:last-child) {
				@include fluid-type(margin-bottom, 1rem, 2rem);
			}

			&:not(:first-child) {
				@include fluid-type(margin-top, 3rem, 4rem);
			}
		}

		h3 {
			@extend .h4;

			&:not(:first-child) {
				@include fluid-type(margin-top, 2rem, 3rem);
			}
		}
	}

	.wp-block-image figcaption {
		color: color(text, light);
		font-size: 90%;
		margin-bottom: 0;
		margin-top: 1rem;
		padding: 0 1em;
	}
}


/**
 * Timeline
 */
.timeline {
	position: relative;

	&:not(.timeline--horizontal)::before {
		@include absolute(top 0 bottom 0 left 50%, 1);
		background-color: color(gray, 300);
		content: '';
		margin-left: -2px;
		width: 4px;
	}

	&__wrap {
		overflow: hidden;
		position: relative;
		z-index: 2;
	}

	&__item {
		font-size: 1rem;
		padding: 10px 40px 10px 0;
		position: relative;
		width: 50%;
		z-index: 2;

		&::after {
			@include absolute(top 50% right -10px, 1);
			background-color: #fff;
			border: 2px solid color(gray, 300);
			border-radius: 50%;
			content: '';
			height: 20px;
			transform: translateY(-50%);
			width: 20px;
		}

		&.animated {
			animation-duration: 1s;
			animation-fill-mode: both;
			opacity: 0;
		}

		&.fadeIn {
			animation-name: fadeIn;
		}

		&--left {
			left: 0;
		}

		&--right {
			left: 50%;
			padding: 10px 0 10px 40px;

			&::after {
				left: -10px;
			}

			.timeline__content {
				&::before {
					border-bottom: 10px solid transparent;
					border-left: none;
					border-right: 12px solid color(gray, 400);
					border-top: 10px solid transparent;
					left: -12px;
				}

				&::after {
					border-bottom: 9px solid transparent;
					border-left: none;
					border-right: 11px solid #fff;
					border-top: 9px solid transparent;
					left: -10px;
				}
			}
		}
	}

	&__content {
		background-color: #fff;
		border: 1px solid color(gray, 400);
		border-radius: $border-radius;
		display: block;
		padding: 1rem;
		position: relative;

		&::before,
		&::after {
			@include transform(y);
			content: '';
			height: 0;
			width: 0;
		}

		&::before {
			border-bottom: 10px solid transparent;
			border-left: 12px solid color(gray, 400);
			border-top: 10px solid transparent;
			right: -12px;
			z-index: 1;
		}

		&::after {
			border-bottom: 9px solid transparent;
			border-left: 11px solid #fff;
			border-top: 9px solid transparent;
			right: -10px;
			z-index: 2;
		}

		span {
			color: color(text, light);
		}

		h3 {
			color: color(text, dark);
		}

		p {
			color: color(text, base);
			margin-bottom: 0;
		}
	}

	&--horizontal {
		font-size: 0;
		overflow: hidden;
		padding: 0 50px;
		white-space: nowrap;

		.timeline-divider {
			@include absolute(right 40px left 40px, 1);
			background-color: color(gray, 300);
			display: block;
			height: 4px;
			transform: translateY(-50%);
		}

		.timeline__items {
			transition: all 0.8s;
			will-change: transform;
		}

		.timeline__item {
			display: inline-block;
			left: 0;
			padding: 0 1px 40px;
			position: relative;
			transition: none;
			vertical-align: top;
			white-space: normal;

			&::after {
				left: 50%;
				right: auto;
				top: 100%;
				transform: translate(-50%, -50%);
			}

			.timeline__item__inner {
				display: table;
				height: 100%;
				width: 100%;
			}

			.timeline__content {
				&__wrap {
					display: table-cell;
					margin: 0;
					padding: 0;
					vertical-align: bottom;
				}

				// Arrow Balloon
				&::before,
				&::after {
					left: 50%;
					right: auto;
					top: 100%;
					transform: translateX(-50%);
				}

				&::before {
					border-left: 12px solid transparent;
					border-right: 12px solid transparent;
					border-top: 12px solid color(gray, 400);
				}

				&::after {
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-top: 10px solid #fff;
				}
			}

			&--bottom {
				padding: 40px 1px 0;

				&::after {
					top: 0;
				}

				.timeline__content {
					&__wrap {
						vertical-align: top;
					}

					// Arrow Balloon
					&::before,
					&::after {
						border-top: none;
						bottom: 100%;
						top: auto;
					}

					&::before {
						border-bottom: 12px solid color(gray, 400);
						border-left: 12px solid transparent;
						border-right: 12px solid transparent;
					}

					&::after {
						border-bottom: 10px solid #fff;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
					}
				}
			}
		}
	}


	&-nav-button {
		@include transform(y, 10);
		align-items: center;
		background-color: #fff;
		border: 2px solid color(gray, 300);
		border-radius: 50%;
		box-shadow: none;
		cursor: pointer;
		display: flex;
		height: 40px;
		justify-content: center;
		outline: none;
		text-indent: -9999px;
		width: 40px;

		&:focus {
			outline: none;
		}

		&:disabled {
			opacity: .5;
			pointer-events: none;
		}

		&::before {
			@extend %chevron;
			margin-top: 0;
			top: 0;
		}

		&--prev {
			left: 0;

			&::before {
				margin-right: -5px;
				transform: rotate(225deg);
			}
		}

		&--next {
			right: 0;

			&::before {
				margin-left: -5px;
				transform: rotate(45deg);
			}
		}
	}

	&--mobile {
		padding: 0;

		&::before {
			left: 10px !important;
			margin: 0 !important;
		}

		.timeline__item {
			left: 0;
			padding-left: 40px;
			padding-right: 0;
			width: 100%;

			&::after {
				left: 2px;
				margin: 0;
			}

			.timeline__content {
				&::before {
					border-bottom: 12px solid transparent;
					border-left: none;
					border-right: 12px solid color(gray, 400);
					border-top: 12px solid transparent;
					left: -12px;
				}

				&::after {
					border-bottom: 10px solid transparent;
					border-left: none;
					border-right: 10px solid #fff;
					border-top: 10px solid transparent;
					left: -10px;
				}
			}
		}
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
			top: 70px;
		}
		100% {
			opacity: 1;
			top: 0;
		}
	}
}

.c-webinar {
	@include media(">=l") {
		align-items: flex-start;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: none;
	}

	.o-page__content {
		@include media(">=l") {
			width: span(8);
		}
	}

	.o-page__sidebar {
		margin-top: 3rem;
		position: sticky;
		top: 7rem;

		@include media(">=l") {
			margin-top: 0;
			width: span(4);
		}
	}
}

.gform_heading {
	display: none;
}
